/* TODO SCROLLBAR INVESTIGATION */
/*
#root {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 2px);
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.displayFlexColumn {
  display: flex;
  flex-direction: column;
}

.displayFlex {
  display: flex;
}

.inputRow {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
  height: 26px;
}

.inputRowTextArea {
  align-items: center;
  display: flex;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.flex4 {
  flex: 4;
}

.flex5 {
  flex: 5;
}

.flex6 {
  flex: 6;
}

.flex7 {
  flex: 7;
}

.flex8 {
  flex: 8;
}

.flex9 {
  flex: 9;
}

.flex10 {
  flex: 10;
}

.width70 {
  width: 70%;
  min-width: 70%;
  max-width: 70%;
}

.halfWidth {
  width: calc(50% - 7px);
  min-width: calc(50% - 7px);
  max-width: calc(50% - 7px);
}

.halfWidthWithPadding {
  width: calc(50% - 7px);
  min-width: calc(50% - 7px);
  max-width: calc(50% - 7px);
  padding-left: 15px;
}

/* TODO SCROLLBAR INVESTIGATION ADD 137 REMOVE 131 132 */
.main {
  width: calc(100vw);
  height: calc(100vh - 106px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 10px;
  background-color: #f0f1f2;
  /* flex: 1; */
}

.mainsection {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.date-picker-input {
  border: 0px;
  height: 16.6875px;
  line-height: 16.6875px;
  max-width: 75px;
  outline: 1px solid rgba(0, 0, 0, 0.23);
  transition: none;
}

.date-picker-input:focus-visible {
  outline: 1px solid #1e365e;
  border-radius: 0;
}

.emptyMenuItem {
  min-height: 20px !important;
}

.select {
  display: grid;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

.select .MuiSelect-select {
  padding-right: 30px !important;
}

/* Data Grid */
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  height: 100%;
}

.tableHeaderIcon {
  width: 10px !important;
  height: 10px !important;
  display: block !important;
}

.tableCellTrucate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Submissions and Bulk Receipting */
.bulkReceipting-filter-bar,
.submission-filter-bar {
  align-items: center;
  background-color: #fff;
  display: flex;
  padding: 10px 15px 10px 15px;
}

.bulkReceipting-main,
.submission-main {
  background-color: #f0f1f2;
  display: flex;
  height: calc(100vh - 179px);
  overflow-x: hidden;
  overflow-y: auto;
  width: calc(100vw);
}

.bulkReceipting-main {
  height: calc(100vh - 214px);
}

.bulkReceipting-top-bar-action-button,
.submission-top-bar-action-button {
  min-width: 0;
  width: auto;
}

.bulkReceipting-top-bar-action-button-icon,
.submission-top-bar-action-button-icon {
  height: 18px;
  margin-right: 5px;
  width: 18px;
}

.bulkReceipting-footer {
  align-items: center;
  border-top: 1px solid rgb(169, 169, 169);
  display: flex;
  font-size: 13.5px;
  font-weight: 500;
  padding-left: 15px;
}

.bulkReceipting-footer-label {
  flex: 1;
  max-width: 120px;
  text-align: right;
}

.bulkReceipting-footer-total-value {
  align-items: end;
  color: "#000";
  flex: 1;
  min-width: 70px;
  text-align: end;
}

.bulkReceipting-total {
  background-color: #fef8f2;
  border-top: 2px solid #f69139;
  color: #db761d;
  padding: 8px;
  padding-right: 15px;
}

.submission-total {
  align-items: center;
  background-color: #fef8f2;
  border-top: 2px solid #f69139;
  color: #db761d;
  display: flex;
  font-size: 13.5px;
  font-weight: 500;
  padding: 3px 6px 3px 15px;
}

.bold-text {
  font-weight: 600 !important;
}

.fee-totals {
  font-weight: 600 !important;
  padding-right: 10px;
  padding-top: 5px;
}

.multi-cell {
  padding: 8px;
  border-left: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  font-weight: 600;
}
.multi-table {
  border-top: 1px solid #bbbfc6;
  display: flex;
}

.multi-cell:first-of-type {
  border-left: 0px;
}

.item-desc .MuiInputBase-input {
  height: 18px !important;
}

/* Invoices */
.invoice-breakdown {
  background-color: #fff;
  height: 161px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}
.poa-breakdown {
  background-color: #fff;
  height: 135px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.invoice-breakdown-bolt-on {
  height: 187px;
}

.invoice-breakdown-lgfs {
  height: 239px;
}

.invoice-breakdown-proceeds {
  height: 136px;
}

.invoice-breakdown-box {
  border: 1px solid #ccc;
  margin-left: 5px;
  margin-right: 5px;
  max-width: 130px;
  min-width: 130px;
  padding: 5px 15px 5px 15px;
}

.invoice-criteria {
  padding: 10px 15px 5px 15px;
}

.invoice-main {
  background-color: #f0f1f2;
  height: calc(100vh - 96px);
  overflow-x: hidden;
  overflow-y: auto;
}

.invoice-total {
  padding-left: 10px;
}

.invoice-total-box {
  background-color: #fff;
  border-left: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  margin-left: 30px;
  padding: 5px;
  text-align: right;
}

.invoice-total-footer {
  background-color: #fef8f2;
  border-top: 2px solid #f69139;
  color: #db761d;
  display: flex;
  font-weight: 500;
  margin-left: 25px;
  padding: 3px 10px 3px 10px;
  text-align: right;
}

.invoice-total-row {
  background-color: #fff;
  border-left: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  margin-left: 25px;
  padding: 3px 10px 3px 10px;
  text-align: right;
}

.invoice-total-row-proceeds {
  margin-left: 8px;
}

.input-row-aligned {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

textarea {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 4px;
  font-family: "Segoe UI";
}

textarea:focus {
  border-radius: 0;
  border: 1px solid #1e365e;
  outline: none;
}

.textarea-fee {
  width: calc(100vw - 169px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.e-form-title::after {
  border-bottom: 1px solid #d8d8d8;
}

.hyperLink.MuiTypography-root {
  color: #0060aa;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
}
/* .date-adornment .MuiIconButton-root {
  padding: 2px;
  border-radius: 0;
} */

.MuiAutocomplete-option {
  font-family: "Segoe UI";
  font-size: 13px;
  padding-left: 4px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.MuiAutocomplete-popupIndicator {
  padding: 0 !important;
  padding-top: 2px !important;
}

.MuiAutocomplete-popupIndicatorOpen {
  padding: 0 !important;
  padding-top: 2px !important;
}

.search-menu-item {
  padding-left: 15px;
  padding-right: 35px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.contextMenu .MuiPaper-root {
  background-color: #f9f9f9;
  padding: 0px 0px;
  border: 1px solid rgb(224, 224, 224);
  box-shadow: 0 2px 5px #0000001a;
  border-radius: 0;
}

.contextMenu .MuiPaper-root .MuiButtonBase-root {
  padding: 4px 20px;
  border-radius: 0;
}

.documentMenu .MuiPaper-root {
  background-color: #f9f9f9;
  padding: 0px 0px;
  border: 1px solid rgb(224, 224, 224);
  box-shadow: 0 2px 5px #0000001a;
  border-radius: 0;
}

.documentMenu .MuiPaper-root .MuiButtonBase-root {
  padding: 4px 20px 4px 5px;
  border-radius: 0;
}
